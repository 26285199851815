"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.lookupAddress = exports.reverseGeocode = exports.geocode = exports.NominatimError = void 0;
var Axios = require("axios");
var Bluebird = require("bluebird");
var NominatimError = /** @class */ (function (_super) {
    __extends(NominatimError, _super);
    function NominatimError(message, requestData) {
        var _this = _super.call(this, message) || this;
        _this.requestData = requestData;
        console.log("New nominatim error", requestData);
        return _this;
    }
    return NominatimError;
}(Error));
exports.NominatimError = NominatimError;
var NOMINATIM_URL = 'https://nominatim.openstreetmap.org';
/**
Creates a webrequest to the given path.
*/
function createRequest(path, data, nominatimUrl) {
    if (data === void 0) { data = {}; }
    //Result should be in JSON
    data["format"] = "json";
    var request = Axios({
        url: nominatimUrl + "/" + path,
        method: "GET",
        params: data,
        responseType: "json",
    });
    return request;
}
;
/**
* Finishes a web request and automatically resolves or rejects it. Pass an optional callback to receive the
* response's string content and the promise resolver.
* @param message The web request method.
* @param resolve The promise resolver.
* @param reject The promise rejecter.
*/
function finishRequest(request) {
    // While it would be nicer to use Bluebird's Promise.resolve here rather than manually resolving and rejecting,
    // we would then lose the error message.
    return new Bluebird(function (res, rej) {
        request.then(function (resp) {
            if (resp.status > 205 || resp.status < 200) {
                return rej(new NominatimError("Response for request did not indicate success. " + resp.status + " " + resp.statusText + ".", resp.data));
            }
            ;
            res(resp.data);
        }).catch(function (e) { return rej(new NominatimError(e.message, e)); });
    });
}
;
/**
 * Creates and handles a complete web request.
 * @param method The request's HTTP method.
 * @param path The request's path.
 * @param data The request's optional querystring or body data object.
 */
function handleFullRequest(path, nominatimUrl, data) {
    var request = createRequest(path, data, nominatimUrl);
    return finishRequest(request);
}
;
/**
 * Lookup the latitude and longitude data for a given address.
 */
function geocode(data, nominatimUrl) {
    if (nominatimUrl === void 0) { nominatimUrl = NOMINATIM_URL; }
    return handleFullRequest("search", nominatimUrl, data);
}
exports.geocode = geocode;
/**
 * Lookup the address data for a pair of latitude and longitude coordinates.
 */
function reverseGeocode(data, nominatimUrl) {
    if (nominatimUrl === void 0) { nominatimUrl = NOMINATIM_URL; }
    return handleFullRequest("reverse", nominatimUrl, data);
}
exports.reverseGeocode = reverseGeocode;
/**
 * Lookup the address of one or multiple OSM objects like node, way or relation.
 */
function lookupAddress(data, nominatimUrl) {
    if (nominatimUrl === void 0) { nominatimUrl = NOMINATIM_URL; }
    return handleFullRequest("lookup", nominatimUrl, data);
}
exports.lookupAddress = lookupAddress;
